export const FONT_SIZES = {
    'xx-small': 'xx-small',
    'x-small': 'x-small',
    small: 'small',
    medium: 'medium',
    large: 'large',
    'x-large': 'x-large',
    'xx-large': 'xx-large',
    'xxx-large': 'xxx-large',
    'xxxx-large': 'xxxx-large',
};
export const FONT_SPACING = {
    dense: 'dense',
    normal: 'normal',
    loose: 'loose',
};
export const INPUT_SIZES = {
    small: 'small',
    medium: 'medium',
    large: 'large',
};
export const THEME_COLORS = {
    default: 'default',
    primary: 'primary',
    secondary: 'secondary',
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
};
